<template>
  <li class="fast-report-connection" v-for="connection in connections" :key="connection.oid">
    <div class="d-flex align-center">
      <template v-if="!hideAvatarsFromConnections">
        <img :src="sourceAvatar(connection)" @load="avatarLoadHandler" @error="avatarErrorHandler" :class="loadImage ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" :class="loadImage ? 'd-none' : 'd-block'">
      </template>
      <Icon :name="socialNetworkIcon(connection.typeid)" color="#A0B2BE" class="mr-16" />
      <span class="score-width fw-600 mr-12">score: {{ connection.fields.score.value }}</span>
      <span class="color-miniheader fw-600">{{name(connection)}}</span>
    </div>
    <div class="d-flex align-center">
      <span class="mr-8">
        {{ connectionTypes(connection) }}
      </span>
      <a :href="url(connection)" target="_blank" class="color-theme">profile</a>
    </div>
  </li>
</template>

<script>
import Icon from '@/components/app/Icon';
import { getEntityAvatar, getEntityUrl, getEntityName } from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'ConnectionMini',
  components: {
    Icon
  },
  props: ['connections'],
  data () {
    return {
      loadImage: false
    };
  },
  computed: {
    ...mapGetters(['hideAvatarsFromConnections'])
  },
  methods: {
    sourceAvatar (profile) {
      return getEntityAvatar(profile);
    },
    avatarLoadHandler () {
      this.loadImage = true;
    },
    avatarErrorHandler () {
      this.loadImage = false;
    },
    socialNetworkIcon (socialNetwork) {
      for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
        if (socialNetwork.includes(socialNet)) {
          return 'person-card-' + socialNet.toLowerCase();
        }
      }
    },
    connectionTypes (profile) {
      return profile.fields.connection_types.value.split(',').join(', ');
    },
    url (profile) {
      return getEntityUrl(profile);
    },
    name (profile) {
      return getEntityName(profile);
    }
  }
};
</script>
