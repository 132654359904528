<template>
  <div class="connection-post d-flex justify-between" style="page-break-inside:avoid">
    <div class="connection-post__left">
      <div class="connection-post__activity" v-if="liked">
        <div class="connection-post__activity-data d-flex align-center mb-14">
          <Icon name="like" data-svg-replace="like" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} liked {{ sourceProfileName }}'s post
          </div>
        </div>
      </div>
      <div class="connection-post__activity" v-if="reacted">
        <div class="connection-post__activity-data d-flex align-center mb-14">
          <Icon name="like" data-svg-replace="like" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} reacted to {{ sourceProfileName }}'s post
          </div>
        </div>
      </div>
      <div class="connection-post__activity" v-if="tagged">
        <div class="connection-post__activity-data d-flex align-center mb-14">
          <Icon name="mention" data-svg-replace="mention" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} tagged with {{ sourceProfileName }}
          </div>
        </div>
      </div>
      <div class="connection-post__activity" v-if="repost">
        <div class="connection-post__activity-data d-flex align-center mb-14">
          <Icon name="repost" data-svg-replace="repost" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ connectionName }} repost {{ sourceProfileName }}'s post
          </div>
        </div>
      </div>
      <div class="connection-post__activity" v-if="mentioned">
        <div class="connection-post__activity-data d-flex align-center mb-14">
          <Icon name="mention" data-svg-replace="mention" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">
            {{ sourceProfileName }} mentioned {{ connectionName }}
          </div>
        </div>
      </div>
      <div class="connection-post__activity" v-if="commented">
        <div class="connection-post__activity-data d-flex align-center mb-4">
          <Icon name="comment" data-svg-replace="comment" class="mr-16" />
          <div class="connection-post__action mr-12 typography-base fw-600">{{ connectionName }} commented {{ sourceProfileName }}'s post</div>
<!--          <div class="mr-12 typography-6">12:20</div>-->
<!--          <div class="typography-6">10.09.2020</div>-->
        </div>
<!--        <div class="connection-post__text mb-14 ml-48 typography-6">"Some text is here"</div>-->
      </div>
    </div>
    <div class="connection-post__right">
      <img class="connection-post__photo" :src="image" @error="imgPostErrorHandler" @load="imgPostLoadHandler" :class="loadImage ? 'd-block' : 'd-none'">
      <img class="connection-post__photo" :src="require('@/assets/images/empty-post-img.png')" :class="loadImage ? 'd-none' : 'd-block'">
      <div class="connection-post__caption">
        <div class="connection-post__date d-flex justify-between mb-16">
          <div class="d-flex justify-between typography-9">
            <div class="mr-12">{{ time }}</div>
            <div>{{ date }}</div>
          </div>
          <div class="connection-post__caption-title typography-caption mb-4 color-light">Post date</div>
        </div>
        <div class="connection-post__content mb-16" v-if="text">
          <div class="connection-post__caption-title typography-caption mb-4 color-theme">Post text</div>
          <div class="connection-post__text typography-9" v-html="text" v-linkified></div>
        </div>
        <div class="connection-post__link">
          <div class="d-flex justify-between mb-12">
            <div class="d-flex align-center typography-6">
              <span class="icon-wrap">
                <Icon :name="socialNetworkIcon" :data-svg-replace="socialNetworkIcon" class="mr-12" />
              </span>
              <a :href="profileUrl" target="_blank" class="color-theme">{{name}}</a>
              <!-- <span v-html="profileId"></span> -->
            </div>
            <div class="connection-post__caption-title typography-caption mb-4 text-right">Source</div>
          </div>
          <a :href="postUrl" class="typography-7 color-theme connection-post-url mb-8 d-block" target="_blank">{{postUrl}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './app/Icon';
import { getEntityName } from '../utils/helpers';
import unraw from 'unraw';

export default {
  name: 'ConnectionPost',
  props: ['post', 'connection', 'source'],
  emits: ['postRelations'],
  data () {
    return {
      loadImage: false
    };
  },
  mounted () {
    this.$emit('postRelations', {
      postID: this.post.id || this.post.value,
      liked: this.liked,
      reacted: this.reacted,
      commented: this.commented,
      repost: this.repost,
      tagged: this.tagged,
      mentioned: this.mentioned
    });
  },
  methods: {
    imgPostLoadHandler () {
      this.loadImage = true;
    },
    imgPostErrorHandler () {
      this.loadImage = false;
    },
    parseConnectionPosts (posts, type) {
      if (posts) {
        return JSON.parse(posts);
      }
      return (this.connection && this.connection[type]) || {};
    },
    relationExists (relationType) {
      const postID = this.post.id || this.post.value;
      for (const relation of this.connection?.relations || []) {
        if ((postID.toString() === relation.target) && relation.type === relationType) {
          return true;
        }
      }
      const photo = this.parseConnectionPosts(this.connection?.fields?.connection_photos?.value, 'connection_photos')[postID];
      if (photo && photo[relationType]) {
        return true;
      }
      const relations = this.connection?.fields?.relations?.value;
      if (relations) {
        for (const relation of JSON.parse(relations)) {
          if ((postID.toString() === relation.target) && relation.type === relationType) {
            return true;
          }
        }
      }
      const post = this.parseConnectionPosts(this.connection?.fields?.connection_posts?.value, 'connection_posts')[postID];
      return !!(post && post[relationType]);
    }
  },
  computed: {
    image () {
      if (this.connection?.typeid === 'maltego.instagram.profile') {
        return document.location.origin + '/proxy/cors/' + this.post?.fields?.image?.value;
      }
      return this.post?.fields?.image?.value || this.post?.fields?.image;
    },
    reverse () {
      return this.post?.reverse || this.post?.fields?.reverse?.value;
    },
    sourceProfileName () {
      const sourceProfile = (this.reverse) ? this.connection : this.source;
      const name = getEntityName(sourceProfile);
      const username = sourceProfile?.fields?.username?.value;
      return name || username;
    },
    connectionName () {
      const connectionProfile = (this.reverse) ? this.source : this.connection;
      const name = getEntityName(connectionProfile);
      const username = connectionProfile?.fields?.username?.value;
      return name || username;
    },
    time () {
      const unixTime = this.post.fields.date.value;
      const date = new Date(unixTime * 1000);
      const hours = date.getHours();
      const minutes = '0' + date.getMinutes();
      return hours + ':' + minutes.substr(-2);
    },
    date () {
      const unixTime = this.post.fields.date.value;
      const date = new Date(unixTime * 1000);
      const day = '0' + date.getDate();
      const month = '0' + (date.getMonth() + 1);
      const year = date.getFullYear();
      return `${day.substr(-2)}.${month.substr(-2)}.${year}`;
    },
    liked () {
      return this.relationExists('like');
    },
    reacted () {
      return this.relationExists('reaction');
    },
    commented () {
      return this.relationExists('comment');
    },
    repost () {
      return this.relationExists('repost');
    },
    tagged () {
      return this.relationExists('with');
    },
    mentioned () {
      return this.relationExists('mention');
    },
    text () {
      const text = this.post.fields.text && this.post.fields.text.value;
      if (text) {
        return unraw(text.replaceAll(/\\n|\\r/g, '<br>'));
      }
      return '';
    },
    url () {
      return this.post.fields.url && this.post.fields.url.value;
    },
    socialNetworkIcon () {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (this.connection.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
        return '';
      } catch (e) {
        return '';
      }
    },
    profileUrl () {
      return this.connection.fields.surl?.value || this.connection.fields.url?.value;
    },
    name () {
      return getEntityName(this.connection);
    },
    postUrl () {
      return this.post.fields.url?.value || this.post.fields.surl?.value;
    }
  },
  components: {
    Icon
  }
};
</script>

<style scoped>

</style>
