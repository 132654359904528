export default {
  methods: {
    getInfoOfProfiles (profiles = []) {
      const info = {};
      profiles.forEach(profile => {
        Object.keys(profile.fields).forEach(key => {
          if (key === 'profile-image') return;
          if (key === 'affiliation.uid') return;
          if (key === 'user-details') return;
          if (key === 'score') return;
          if (key === 'connection') return;
          if (key === 'commented_count') return;
          if (key === 'reacted_count') return;
          if (key === 'tagged_count') return;
          if (key === 'liked_count') return;
          if (key === 'repost_count') return;
          if (key === 'mentioned_count') return;
          if (key === 'connection_types') return;
          if (key === 'relations') return;
          if (key === 'post_exists') return;
          if (key.indexOf('search-') !== -1) return;
          if (key === 'info') {
            profile.fields[key].value = profile.fields[key].value.split('###').join(', ');
          }
          if (key === 'number_id' || key === 'fs_id' || key === 'twitter.id') {
            this.writeInfoField(info, 'id', profile, key);
          } else if (key === 'name' || key === 'person.name') {
            this.writeInfoField(info, 'name', profile, key);
          } else if (key === 'surl' || key === 'affiliation.profile-url') {
            this.writeInfoField(info, 'links', profile, key);
          } else if (key === 'alias' || key === 'twitter.screen-name') {
            this.writeInfoField(info, 'alias', profile, key);
          } else {
            this.writeInfoField(info, null, profile, key);
          }
        });
      });
      return info;
    },
    writeInfoField (info, name, profile, key) {
      if (!profile.fields[key].value) return;
      if (!name) {
        name = key;
      }
      if (key === 'twitter.friendcount') {
        name = 'friends';
      } else if (key === 'followers_count') {
        name = 'followers';
      } else if (key === 'tweets_count') {
        name = 'tweets';
      } else if (key === 'created_at') {
        name = 'created';
      }
      const profileKeyValue = profile.fields[key].value;
      let infoName = info[name] || null;
      if (!info[name]) {
        info[name] = {};
        infoName = info[name];
        if (name === 'name') {
          infoName.value = [];
          infoName.value.push(profileKeyValue);
        } else {
          infoName.value = profileKeyValue;
        }
        infoName.socialNetwork = [];
      } else {
        if (name === 'name') {
          infoName.value.push(profileKeyValue);
        } else if (infoName.value !== profileKeyValue) {
          infoName.value = infoName.value + ', ' + profileKeyValue;
        }
      }
      if (!infoName.socialNetwork.includes(profile.typeid)) {
        infoName.socialNetwork.push(profile.typeid);
      }
      if (info[name].value && info[name].value.indexOf('###') !== -1) {
        info[name].value = info[name].value.split('###');
      }
    },
    getUserDetails (profiles = []) {
      const arr = [];
      profiles.forEach(profile => {
        if (profile.fields['user-details']) {
          const obj = JSON.parse(profile.fields['user-details'].value);
          for (const key in obj) {
            obj[key].forEach(val => {
              val.socialNetwork = profile.typeid;
            });
          }
          arr.push(obj);
        }
      });
      const userDetailsObj = {};
      arr.forEach(obj => {
        for (const key of Object.keys(obj)) {
          if (userDetailsObj[key]) {
            userDetailsObj[key].push(...obj[key]);
          } else {
            userDetailsObj[key] = obj[key];
          }
        }
      });
      return userDetailsObj;
    },
    getSortingUserDetails (object = {}) {
      const sortedUserDetails = {};
      for (const type of Object.keys(object)) {
        object[type].forEach(obj => {
          let sortedValue = sortedUserDetails[type];
          if (!sortedValue) {
            sortedUserDetails[type] = {};
            sortedValue = sortedUserDetails[type];
          }
          for (const name of Object.keys(obj)) {
            if (name !== 'socialNetwork') {
              if (!(name in sortedValue)) {
                sortedValue[name] = {
                  value: obj[name],
                  socialNetwork: [obj.socialNetwork]
                };
              } else {
                sortedValue[name].value = sortedValue[name].value + ', ' + obj[name];
                if (!sortedValue[name].socialNetwork.includes(obj.socialNetwork)) {
                  sortedValue[name].socialNetwork.push(obj.socialNetwork);
                }
              }
            }
          }
        });
      }
      return sortedUserDetails;
    }
  }
};
